import { gql } from "@apollo/client";

export const GET_PERSON = gql`
	query GetPerson($id: String!) {
		person(id: $id) {
			id
			name
			email
			phone
			customer
			venue
			alert
			category {
				label
				color
			}
			description
			mainFace
		}
		personVenues(id: $id) {
			id
			name
		}
		personFaces(id: $id) {
			id
			url
		}
	}
`;

export const GET_PEOPLE = gql`
	query GetPeople(
		$skip: Int!
		$limit: Int!
		$customerFilter: [String!] = []
		$venueFilter: [String!] = []
		$searchTerm: String = ""
	) {
		people(
			skip: $skip
			limit: $limit
			customers: $customerFilter
			venues: $venueFilter
			searchTerm: $searchTerm
		) {
			count
			items {
				id
				name
				email
				phone
				customer
				venue
				alert
				category {
					label
					color
				}
			}
		}
	}
`;

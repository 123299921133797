import { GET_PEOPLE } from "../../schemas/queries/People";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Pagination } from "../../components/Pagination/Pagination";
import PeopleTableRow from "./PeopleTableRow";
import { useCustomerStore } from "../../store/Customer";
import { useQuery, NetworkStatus } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const PeopleTable = () => {
	const navigate = useNavigate();
	const [people, setPeople] = useState([]);
	const [count, setCount] = useState(0);
	//   Pagination
	const [skip, setSkip] = useState(0);
	const [limit, setLimit] = useState(10);
	const [initialLoading, setInitialLoading] = useState(true);

	//   Search Query
	const [searchTerm, setSearchTerm] = useState("");

	const { customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customerFilter: state.customerFilter,
		}))
	);

	const peopleQuery = useQuery(GET_PEOPLE, {
		variables: {
			skip: skip * limit,
			limit,
			customerFilter: customerFilter,
			searchTerm: searchTerm?.trim(),
		},

		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		onError: (error) => {
			console.error(error);
			if (error.message === "Permission denied") {
				navigate("/error", {
					state: {
						status: 401,
						error: "Unauthorized to access facial recognition.",
					},
				});
			}
		},
		onCompleted: (data) => {
			setPeople(data.people.items);
			setCount(data.people.count);
			setInitialLoading(false);
		},
		fetchPolicy: "network-only",
	});

	return (
		<div className="flow-root mt-8">
			<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<div>
							{initialLoading ? (
								<div className="min-w-full min-h-[20vw] flex m-auto items-center justify-center">
									<LoadingSpinner w={24} h={24} />
								</div>
							) : (
								<>
									{/* Search Bar */}
									<div className="flex w-3/4 pb-2 mb-6 border-0 rounded-md shadow-sm sm:mx-auto sm:w-full max-h-10">
										<div className="relative flex w-full border-0 focus-within:z-10">
											<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
												<svg
													className="w-5 h-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor"
													aria-hidden="true"
												>
													<path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
												</svg>
											</div>
											<input
												type="text"
												name="person"
												id="person"
												className="block w-full py-2 pl-10 text-gray-900 bg-white rounded-md dark:bg-blue-xonar dark:text-gray-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												placeholder="Name"
												value={searchTerm}
												onChange={(e) =>
													setSearchTerm(
														e.target.value
													)
												}
											/>
										</div>
									</div>
									{/* Table of People */}
									<table className="min-w-full divide-y divide-gray-300 dark:divide-gray-300/20">
										<thead className="bg-gray-50 dark:bg-gray-900">
											<tr>
												<th
													scope="col"
													className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 sm:pl-6"
												>
													Full Name
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
												>
													Category
												</th>
												<th
													scope="col"
													className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
												>
													Arrival Alerts
												</th>
												<th
													scope="col"
													className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
												>
													Email
												</th>
												<th
													scope="col"
													className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
												>
													Phone
												</th>
												<th
													scope="col"
													className="relative py-3.5 pl-3 pr-4 sm:pr-6"
												>
													<span className="sr-only">
														People Actions
													</span>
												</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-200/20 dark:bg-gray-900/80">
											{peopleQuery?.networkStatus ===
											NetworkStatus.setVariables ? (
												<>
													{[
														...Array(limit).keys(),
													].map((index) => (
														<tr
															key={`people-row-filler-${index}`}
														>
															<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-gray-200 sm:pl-6" />
															<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap dark:text-gray-300" />
															<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap dark:text-gray-300" />
															<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap dark:text-gray-300" />
															<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap dark:text-gray-300" />
															<td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6" />
														</tr>
													))}
												</>
											) : (
												peopleQuery?.data?.people?.items.map(
													(person) => {
														return (
															<PeopleTableRow
																key={person.id}
																personData={
																	person
																}
															/>
														);
													}
												)
											)}
										</tbody>
									</table>
									<Pagination
										skip={skip}
										onSkip={setSkip}
										count={count}
										limit={limit}
										currentAmount={people.length}
										className="min-w-full mt-1 divide-y divide-gray-300"
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PeopleTable;
